.FooterMain {
  display: flex;
  color: white;
  width: 100%;
  margin: 0 10px;
  padding-top: 50px;
  justify-content: space-around;
}
.f-sec1 {
  background-color: #1c1924;
  display: flex;
  width: 70%;
  border-top-left-radius: 10px;
  justify-content: space-around;
  padding: 10px 20px;
}
.f-sec2 {
  display: flex;
  width: 29%;
  flex-direction: column;
  gap: 7px;
  justify-content: center;
}
.socialMain img {
  border: 1px solid white;
  border-radius: 50px;
  padding: 5px;
  margin: 0px 10px;
  cursor: pointer;
}
.socialMain img:hover {
  border: 1px solid #fef100;
}
.fImg1 {
  transition: transform 0.3s ease-in-out;
}
.fImg1:hover {
  transform: scale(1.2);
}
.socialMain {
  display: flex;
  padding: 35px 20px;
  background-color: #1c1924;
  border-top-right-radius: 10px;
  justify-content: center;
}

.socialMain2 {
  display: flex;
  padding: 30px 20px;
  background-color: #1c1924;
  justify-content: space-evenly;
}
.socialMain2 img {
  width: 200px;
}
.f-sec1 h2 {
  font-weight: 700;
}
.sec-link {
  font-weight: 400;
  padding: 7px 0px;
  letter-spacing: 1px;
}
.footerlink {
  color: #ffffff;
  text-decoration: none;
}
.footerlink:hover {
  color: #fef100;
  text-decoration: none;
}

@media screen and (max-width: 769px) and (min-width: 426px) {
  .f-sec1 h2 {
    padding-top: 10px;
    font-weight: 600;
    font-size: 3vw;
  }
  .sec-link {
    font-size: 2.2vw;
  }
  .footerlink {
    font-size: 2vw;
  }
  .f-sec2 {
    width: 28%;
  }
  .fImg1 {
    width: 27px;
    height: auto;
  }
  .socialMain img {
    margin: 0vh 1vw;
  }
  .socialMain2 img {
    width: 25vw;
    height: auto;
  }
}

@media screen and (max-width: 426px) {
  .FooterMain {
    display: flex;
    flex-direction: column-reverse;
  }
  .f-sec1 {
    display: flex;
    width: 100%;
    border-top-left-radius: 0px;
  }
  .f-sec2 {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding: 6px 0;
  }
  .f-sec1 h2 {
    padding-top: 10px;
    font-weight: 600;
    font-size: 3vw;
  }
  .sec-link {
    font-size: 2.2vw;
  }
  .footerlink {
    font-size: 2vw;
  }
  .fImg1 {
    width: 27px;
    height: auto;
  }
  .socialMain,
  .socialMain2 {
    width: 50%;
  }
  .socialMain {
    padding: 20px 15px;
    align-items: center;
    border-top-right-radius: 10px;
  }
  .socialMain2 {
    border-top-left-radius: 10px;
    display: flex;
    padding: 20px 10px;
  }
  .socialMain img {
    margin: 0px 4px;
    width: 25px;
    height: 25px;
  }
  .socialMain2 img {
    width: 35vw;
    height: auto;
  }
}
